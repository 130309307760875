import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { getTokenOrRefresh } from './token_util';
import './custom.css'
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import { useEffect, useRef } from 'react';
import { Player, FS_SDK_EVENTS_NAME, FS_QUALITY_VALUES } from 'furioos-sdk';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')


export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayText: 'INITIALIZED: ready to test speech...'
        }
    }
    
    async componentDidMount() {
        // check for valid speech key/region
        const tokenRes = await getTokenOrRefresh();
        if (tokenRes.authToken === null) {
            this.setState({
                displayText: 'FATAL_ERROR: ' + tokenRes.error
            });
        }

        const options = {
            whiteLabel: true,
            hideToolbar: false,
            hideTitle: true,
            hidePlayButton: false,
            debugAppMode: false,
            inactiveTimeout: 60000,
          };
        
          this.player = new Player(process.env.REACT_APP_SDKLINKID ,"furioos_container", options);
        
          // Bind player loaded
          this.player.on(FS_SDK_EVENTS_NAME.LOAD, () => {
            console.log("SDK client FIRED: Player loaded");
          });
        
          // Bind application install progress
          this.player.on(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS, (data) => {
            console.log("SDK client FIRED: App install progress", data);
          });
        
          // Bind application start
          this.player.on(FS_SDK_EVENTS_NAME.ON_APP_START, () => {
            console.log("SDK client FIRED: App start");
          });
        
          // Bind stream start
          this.player.on(FS_SDK_EVENTS_NAME.ON_STREAM_START, () => {
            console.log("SDK client FIRED: Stream start");
          });
        
          // Bind stream start
          this.player.on(FS_SDK_EVENTS_NAME.ON_SDK_START, () => {
            console.log("SDK client FIRED: SDK start");
          });
        
          // Bind SDK messages
          this.player.on(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE, (data) => {
            console.log("SDK Message Received:", data);
          });
        
          // Bind an event that lets you know if you can resume session
          this.player.on(FS_SDK_EVENTS_NAME.ON_RESUME_SESSION, ({ canResumeSession }) => {
            if(canResumeSession) {
              this.player.resumeSession();
            }
          });
        
          // Bind session stoppeds
          this.player.on(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED, () => {
            console.log("SDK client FIRED: Session Stopped");
          });
    }
    componentWillUnmount() {
        // Remove player loaded event listener
        this.player.off(FS_SDK_EVENTS_NAME.LOAD);
      
        // Remove application install progress event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS);
      
        // Remove application start event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_APP_START);
      
        // Remove stream start event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_STREAM_START);
      
        // Remove SDK start event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_SDK_START);
      
        // Remove SDK message event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE);
      
        // Remove resume session event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_RESUME_SESSION);
      
        // Remove session stopped event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED);
    }
    
    async sendFurioosSTTMessage(status,message){
        console.log("SDK Example: Call sendSDKMessage", new Date());
        this.player.sendSDKMessage({
          "STTstatus": status,
          "STTmessage": message
        });
    }
    
    async sttFromMic() {
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';
        
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

        this.setState({
            displayText: 'speak into your microphone...'
        });

        recognizer.recognizeOnceAsync(result => {
            let displayText;
            if (result.reason === ResultReason.RecognizedSpeech) {
                displayText = `RECOGNIZED: Text=${result.text}`
            } else {
                displayText = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
            }

            this.setState({
                displayText: displayText
            });
        });
    }

    async realtimeSttFromMic() {
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';
        
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
        this.setState({
            displayText: 'speak into your microphone (realtime)...'
        });
        // Start the continuous recognition. Note that, in this continuous scenario, activity is purely event-
        // driven, as use of continuation (as is in the single-shot sample) isn't applicable when there's not a
        // single result.
        recognizer.startContinuousRecognitionAsync();
        recognizer.recognizing = this.onRecognizing;
        recognizer.recognized = this.onRecognized;
        recognizer.canceled = this.onCanceled;
        recognizer.sessionStarted = this.onSessionStarted;
        recognizer.sessionStopped = this.onSessionStopped;

        recognizer.recognized = (sender, recognitionEventArgs) => {
            var result = recognitionEventArgs.result;
            console.log("recognised"+result.text)
            this.sendFurioosSTTMessage("Recognised",result.text)
          }
    }

    onRecognizing(sender, recognitionEventArgs) {
        var result = recognitionEventArgs.result;
        console.log("recognising"+result.text)
        // var disptext = `(recognizing) Reason: ${speechsdk.ResultReason[result.reason]}`
        // + ` Text: ${result.text}\r\n`;
        // this.setState({
        //     displayText: result.text
        // });
    }
    sendToFurioos(rec,mes){
        console.log(rec + mes)
    }
    onRecognized(sender, recognitionEventArgs) {
        var result = recognitionEventArgs.result;
        console.log("recognised"+result.text)
        this.sendFurioosSTTMessage("Recognised",result.text)
        // var disptext = `(recognizing) Reason: ${speechsdk.ResultReason[result.reason]}`
        // + ` Text: ${result.text}\r\n`;
        // this.setState({
        //     displayText: result.text
        // });
    }
    onSessionStarted(sender, sessionEventArgs) {
        console.log("Session started"+sessionEventArgs.sessionId)
        // var disptext =  `(sessionStarted) SessionId: ${sessionEventArgs.sessionId}\r\n`;
        // this.setState({
        //     displayText: sessionEventArgs.sessionId
        // });
    }
    onSessionStopped(sender, sessionEventArgs) {
        console.log("Session stopped"+ sessionEventArgs.sessionId)
        // var disptext =  `(sessionStopped) SessionId: ${sessionEventArgs.sessionId}\r\n`;
        // this.setState({
        //     displayText: sessionEventArgs.sessionId
        // });
    }
    onCanceled (sender, cancellationEventArgs) {
        console.log("cancelled!")
        console.log(cancellationEventArgs);

        // var disptext = "(cancel) Reason: " + speechsdk.CancellationReason[cancellationEventArgs.reason];

        // if (cancellationEventArgs.reason === speechsdk.CancellationReason.Error) {
        //     disptext = ": " + cancellationEventArgs.errorDetails;
        // }
        // this.setState({
        //     displayText: disptext
        // });
    }


    async fileChange(event) {
        const audioFile = event.target.files[0];
        console.log(audioFile);
        const fileInfo = audioFile.name + ` size=${audioFile.size} bytes `;

        this.setState({
            displayText: fileInfo
        });

        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';

        const audioConfig = speechsdk.AudioConfig.fromWavFileInput(audioFile);
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

        recognizer.recognizeOnceAsync(result => {
            let displayText;
            if (result.reason === ResultReason.RecognizedSpeech) {
                displayText = `RECOGNIZED: Text=${result.text}`
            } else {
                displayText = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
            }

            this.setState({
                displayText: fileInfo + displayText
            });
        });
    }


    

    render() {
        return (
            <Container className="app-container">
                <h1 className="display-4 mb-3">Speech sample app</h1>

                <div className="row main-container">
                    <div className="col-6">
                        <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.sttFromMic()}></i>
                        Convert speech to text from your mic.

                        <div className="mt-2">
                        <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.realtimeSttFromMic()}></i>
                        Start realtime speech to text from your mic.
                        </div>

                        <div className="mt-2">
                            <label htmlFor="audio-file"><i className="fas fa-file-audio fa-lg mr-2"></i></label>
                            <input 
                                type="file" 
                                id="audio-file" 
                                onChange={(e) => this.fileChange(e)} 
                                style={{display: "none"}} 
                            />
                            Convert speech to text from an audio file.
                        </div>
                    </div>
                    <div className="col-6 output-display rounded">
                        <code>{this.state.displayText}</code>
                    </div>
                    <div>
                    <div id="furioos_container"  style={{ width: '800px', height: '600px' }}/></div>

                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.sendFurioosSTTMessage("testing","testing")}></i>
                    send sample message to furioos
                    </div>

                </div>
            </Container>
        );
    }
}